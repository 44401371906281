<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <repair-form
      v-else
      :formErrors="formErrors"
      :repair-data="repair"
      :loading="loading"
      @onEditRepair="onEditRepair"
      @onViewRepair="onViewRepair"
      @onCloseRepairModal="onCloseRepairModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultRepair from "../defaultRepair";
import RepairForm from "../partials/RepairForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    RepairForm,
  },

  props: {
    vehicle: {
      type: Number | String,
      default: null,
    },
  },

  mixins: [alertLeave],

  data() {
    const repairData = cloneDeep(defaultRepair);
    // repairData = this.$fillUserOrganizationData(repairData);
    repairData.vehicle_vin = this.$route.query.vehicle_vin ?? null;
    // repairData.vehicle.id = this.$route.query.vehicle_id ?? null;
    repairData.inspection.id = this.$route.query.inspection_id ?? null;
    repairData.customer.id = this.$route.query.customer_id ?? null;
    // repairData.repair.id = this.$route.query.repair_id;
    return {
      repair: repairData,
      formErrors: null,
      loading: false,
    };
  },

  async created() {
    if (this.vehicle) {
      await this.getVehicle(this.vehicle);
    }
  },

  methods: {
    onEditRepair(repair) {
      this.$emit("onEditRepair", repair);
    },

    async getVehicle(vehicleId) {
      this.loading = true;
      try {
        await this.$store.dispatch("vehicles/get", vehicleId);
        const vehicle = await this.$store.getters["vehicles/vehicle"];
        this.repair.vehicle = vehicle;
        this.repair.organization = vehicle.organization;
        this.repair.allowedLocations = vehicle.allowedLocations;
        this.repair.customer = vehicle.customer;
        this.repair.approver = vehicle.mecanic;
        this.repair.odometer = vehicle.odometer;
        this.repair.odometer_unit = vehicle.odometer_unit;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    onViewRepair(repair) {
      this.$emit("onViewRepair", repair);
    },
    onCloseRepairModal() {
      this.$emit("onCloseRepairModal");
    },
  },
};
</script>
