<template>
  <div class="estimate-details">
    <!-- <div class="add-inspection-steps-header">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step
          :title="$t('REPAIRS.COMPONENTS')"
          class="cursor-pointer"
          @click.native="switchToStep(COMPONENTS_STEP)"
        />
      </el-steps>
    </div> -->
    <div class="add-inspection-steps-content">
      <!-- <div class="pb-4">
        <div class="inspection-form-title">
          <h3>{{ $t("COMMON.RESPONSIBLE") }}</h3>
        </div>

        <div class="inspection-form-divider"></div>

        <repair-view-repair-managers
          :repair="repair"
          @onChangeComponentsRepairManager="onChangeComponentsRepairManager"
          @onChangeTimesRepairManager="onChangeTimesRepairManager"
        />
      </div> -->
      <div class="position-relative">
        <div
          class="estimate-overlay position-absolute w-100 h-100 top-0 p-6 z-10 d-flex flex-column align-items-center justify-content-center"
          v-if="otherIsRepairing"
        >
          <img
            class="mb-4"
            src="/img/estimation-in-progress.svg"
            alt="repair in progress"
          />
          <span class="h1 text-darker">
            {{ $t("REPAIRS.OTHER_WORK_ON_REPAIR") }}
          </span>
          <div class="inspection-form-group next-button justify-content-center">
            <base-button
              class="btn btn-sm elite-submit"
              size="sm"
              type="button"
              @click="showTakeOverModal = true"
              v-if="canTakeOverRepair(repair)"
            >
              {{ $t("REPAIRS.TAKE_OVER_REPAIR") }}
            </base-button>
          </div>
        </div>
        <div
          class="estimate-details-body"
          :class="{ 'blur-sm': otherIsRepairing }"
        >
          <div class="estimate-details-body-header">
            <div class="estimate-details-body-header-left">
              <h3>{{ $t("REPAIRS.REPAIR_DETAIL") }}</h3>
            </div>
            <!-- <div class="estimate-details-body-header-right">
              <base-button
                class="btn btn-sm text-nowrap"
                type="button"
                v-if="canGenerateInspectionReport"
                @click="downloadInspectionReport"
              >
                {{ $t("REPAIRS.INSPECTION_REPORT") }}
                <i class="far fa-file-pdf"></i>
              </base-button>
            </div> -->
          </div>
          <div class="custom-separator-bold"></div>
          <div class="estimate-details-body-content">
            <div class="list">
              <div class="view-form" ref="repair_items_form">
                <repair-item-form
                  v-for="(item, index) in items"
                  ref="items"
                  :key="item?.id ?? index"
                  :item.sync="item"
                  :editable="repairAreEditable(repair)"
                  :can-remove-item="
                    repairAreEditable(repair) && items.length > 1
                  "
                  :isLastItem="repairAreEditable(repair) && lastItemKey == index"
                  @update:item="(itemModel) => itemChanged(index, itemModel)"
                  @openCommentModal="openCommentModal"
                  @deleteItem="deleteItem(index)"
                  @addNewItem="addItem(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="steps-content-footer">
        <div class="mr-auto align-content-center">
          <Button
            class="cancel"
            v-if="canCancelRepair(repair)"
            @click="showRepairCancelModel = true"
          >
            {{ $t("REPAIRS.CANCEL_REPAIR") }}
          </Button>
          <Button
            class="next mt-2"
            @click="endRepair"
            v-if="canEndRepair(repair)"
          >
            <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
            {{ $t("REPAIRS.END_REPAIR") }}
          </Button>
          <!-- <Button class="next mt-2" @click="() => {}" v-else>
            {{ $t("REPAIRS.START_REPAIR") }}
            <i class="fal fa-long-arrow-right"></i>
          </Button> -->
        </div>
      </div>
    </div>

    <modal :show.sync="showMessageModal" modal-classes="message">
      <template slot="header">
        <h5 class="modal-title" id="inspection-message">
          {{ $t("INSPECTIONS.ADD_COMMENT") }}
        </h5>
      </template>
      <div>
        <base-input
          type="textarea"
          :label="$t('INSPECTIONS.COMMENT')"
          :placeholder="$t('INSPECTIONS.COMMENT')"
        >
          <textarea v-model="comment"></textarea>
        </base-input>
      </div>
      <template slot="footer">
        <base-button
          type="secondary"
          class="btn close"
          @click="showMessageModal = false"
        >
          {{ $t("COMMON.CLOSE") }}
        </base-button>
        <base-button type="primary" class="btn save">
          {{ $t("INSPECTIONS.ADD_COMMENT") }}
        </base-button>
      </template>
    </modal>

    <modal :show.sync="showPictureModal" modal-classes="picture">
      <template slot="header">
        <h5 class="modal-title" id="inspection-picture">
          {{ $t("INSPECTIONS.ADD_PICTURES") }}
        </h5>
      </template>
      <div>
        <gallery-selector
          :label="$t('COMMON.PICTURES')"
          :defaultGallery="images"
          @galleryChanged="
            (gallery_urls) => {
              images = gallery_urls;
            }
          "
        />
      </div>
      <template slot="footer">
        <base-button
          type="secondary"
          class="btn close"
          @click="showPictureModal = false"
        >
          {{ $t("COMMON.CLOSE") }}
        </base-button>
        <base-button type="primary" class="btn save" @click="addImages">
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </template>
    </modal>

    <inspection-confirmation-modal
      :confirm-action="cancelRepair"
      :confirm-button-text="$t('COMMON.YES_CANCEL')"
      :loading="isLoading"
      :message="$t('REPAIRS.CANCEL_THIS_REPAIR')"
      :open.sync="showRepairCancelModel"
      modal-classes="modal-secondary cancel"
    />

    <inspection-confirmation-modal
      :confirm-action="startRepair"
      :confirm-button-text="$t('COMMON.YES_START')"
      :loading="isLoading"
      :message="$t('REPAIRS.START_THIS_REPAIR')"
      :open.sync="showStartRepairModal"
      modal-classes="modal-secondary validate"
    />

    <inspection-confirmation-modal
      :confirm-action="endRepair"
      :confirm-button-text="$t('COMMON.YES_END')"
      :loading="isLoading"
      :message="$t('REPAIRS.END_THIS_REPAIR')"
      :open.sync="showEndRepairModal"
      modal-classes="modal-secondary validate"
    />

    <inspection-confirmation-modal
      :confirm-action="takeOverRepair"
      :confirm-button-text="$t('REPAIRS.YES_TAKE_OVER')"
      :loading="loading"
      :message="
        $t('REPAIRS.TAKE_OVER_THIS_REPAIR', {
          name: $objectDenomination(repair.startedBy),
        })
      "
      :open.sync="showTakeOverModal"
      modal-classes="modal-secondary take-over start"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep, isEqual } from "lodash";
import { Button, Option, Select, Step, Steps } from "element-ui";
import GallerySelector from "@/components/GallerySelector.vue";
import RepairItemForm from "./RepairItemForm.vue";
import formMixin from "@/mixins/form-mixin";
import {
  repairItemConditionsOptionsDisplay,
  repairItemStatusesOption,
} from "@/constants/repairItems";
import defaultRepairItem from "../defaultRepairItem";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  REPAIR_STATUS_IN_PROGRESS_APPROVE,
  REPAIR_STATUS_IN_PROGRESS,
  REPAIR_TYPES_STATUS_COMPLETED,
} from "@/constants/repairs";
import { REPAIR_ITEM_STATUS_APPROVED } from "@/constants/repairItems";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import repairPermissionsMixin from "@/mixins/repair-permissions-mixin";
import RepairViewRepairManagers from "./RepairViewRepairManagers.vue";
import swal from "sweetalert2";

export default {
  name: "repair-view-repair",

  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Steps.name]: Steps,
    [Step.name]: Step,
    GallerySelector,
    RepairItemForm,
    InspectionConfirmationModal,
    RepairViewRepairManagers,
  },

  mixins: [formMixin, requestErrorMixin, repairPermissionsMixin],

  props: {
    department: {
      type: String,
      default: null,
      description: "Department id",
    },
    repairType: {
      type: String,
      default: null,
      description: "Repair Type id",
    },
    assignTo: {
      type: String,
      default: null,
      description: "Assign id",
    },
    hourlyRateValue: {
      type: Number,
      default: "0",
    },
    repairData: {
      type: Object,
      default: null,
      description: "Repair data",
    },
    formErrors: {
      type: [Object, Array],
      default: null,
      description: "Form errors",
    },
    loading: {
      type: Boolean,
      default: false,
      description: "Loading",
    },
  },

  data() {
    let repairData = { ...this.repairData };
    repairData = this.$fillUserOrganizationData(repairData);

    const itemData = {
      relationshipNames: defaultRepairItem.relationshipNames,
      organization: {
        ...defaultRepairItem.organization,
        id: repairData?.organization?.id,
      },
      repair: {
        ...defaultRepairItem.repair,
        id: this.repairData.id,
      },
    };

    let items = [];

    this.repairData.items.forEach(
      (item) => (items = [...items, { ...item, ...itemData }])
    );

    let itemExample = cloneDeep({
      ...defaultRepairItem,
      ...itemData,
      work_amount: this.hourlyRateValue,
    });

    if (items.length < 1) {
      items.push(cloneDeep(itemExample));
    }

    return {
      commentSectionModalOpened: true,
      showPictureModal: false,
      showMessageModal: false,
      departmentModel: this.department,
      repairTypeModel: this.repairType,
      assignToModel: this.assignTo,
      hourlyRate: this.hourlyRateValue,
      repair: repairData,
      items,
      images: [],
      comment: String,
      conditionOptionsDisplay: repairItemConditionsOptionsDisplay,
      statusOptions: repairItemStatusesOption,
      itemExample,
      showRepairCancelModel: false,
      showStartRepairModal: false,
      showEndRepairModal: false,
      showStartApproveRepairModal: false,
      showEndApproveRepairModal: false,
      showTakeOverModal: false,
    };
  },

  computed: {
    ...mapGetters({
      me: "profile/me",
    }),

    comments: function () {
      return [{ id: 2, type: "comments", comment: "comments", owner: this.me }];
    },

    canEditStatus: function () {
      return (
        !!this.repair &&
        this.$currentUserCan(this.$permissions.PERM_APPROVE_ESTIMATIONS) &&
        this.repair.status == REPAIR_STATUS_IN_PROGRESS_APPROVE
      );
    },

    canGenerateInspectionReport: function () {
      return (
        !!this.repair.inspection &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_INSPECTIONS)
      );
    },

    lastItemKey: function () {
      return Math.max(this.items.length - 1, 0);
    },

    otherIsRepairing: function () {
      return (
        this.repairAreEditable(this.repair) &&
        [REPAIR_STATUS_IN_PROGRESS].includes(this.repair.status) &&
        !this.amRepairing(this.repair)
      );
    },

    approvedAll: {
      get() {
        return !this.items.find(
          (item) => item.status != REPAIR_ITEM_STATUS_APPROVED
        );
      },
      set(check) {
        if (check) {
          const items = cloneDeep(this.items);
          for (const itemKey in items) {
            items.splice(itemKey, 1, {
              ...items[itemKey],
              status: REPAIR_ITEM_STATUS_APPROVED,
            });
          }
          this.items = items;
        } else {
          const items = cloneDeep(this.items);
          for (const itemKey in items) {
            items.splice(itemKey, 1, {
              ...items[itemKey],
              status: "",
            });
          }
          this.items = items;
        }
      },
    },
    canShowReviewAlert: function () {
      return [
        REPAIR_STATUS_IN_PROGRESS_APPROVE,
        REPAIR_STATUS_IN_PROGRESS,
      ].includes(this.inspection);
    },
  },

  async created() {
    // await this.$store.dispatch(
    //   "organizationOptions/getOrganizationConfig",
    //   this.repairData.organization.id
    // );
    // this.hourlyRate =
    //   this.organizationConfig.DEFAULT_ESTIMATION_ITEM_HOURLY_RATE ?? 0;
  },

  methods: {
    addItem(index) {
      index++;
      let item = cloneDeep(this.itemExample);
      this.items = cloneDeep([
        ...this.items.slice(0, index),
        item,
        ...this.items.slice(index),
      ]);
      this.$notify({
        type: "success",
        timeout: 3000,
        message: this.$t("REPAIRS.REPAIR_ITEM_ADDED"),
      });
    },

    addImages() {
      /* this.fieldModel.images = cloneDeep(this.images);
      this.showPictureModal = false;
      this.$emit("fieldChanged", cloneDeep(this.fieldModel)); */
    },

    addMessage() {
      /*  this.fieldModel.comment = this.comment;
      this.showMessageModal = false;
      this.$emit("fieldChanged", cloneDeep(this.fieldModel)); */
    },

    openCommentModal() {
      this.commentSectionModalOpened = true;
    },

    closeCommentModal() {
      this.commentSectionModalOpened = false;
    },

    deleteItem(index) {
      this.items.splice(index, 1);
    },

    itemChanged(index, item) {
      if (!this.isLoading) {
        if (this.canStartRepair(this.repair)) {
          this.startRepair();
        }
      }

      // this.items.splice(index, 1, item);
    },

    itemIsApproved(item) {
      return item?.status == REPAIR_ITEM_STATUS_APPROVED;
    },

    cancelRepair() {
      this.$emit("onCancelRepair", true);
    },

    startRepair() {
      this.$emit("onStartRepair", true);
    },

    endRepair() {
      this.$emit("onEndRepair", true);
    },

    startApproveRepair() {
      this.$emit("onStartApproveRepair", true);
    },

    endApproveRepair() {
      this.$emit("onEndApproveRepair", true);
    },

    takeOverRepair() {
      this.$emit("onTakeOverRepair", {
        repairId: this.repair.id,
        data: { user_id: this.me.id },
      });
    },

    onChangeComponentsRepairManager(data) {
      this.$emit("onTakeOverRepair", {
        repairId: this.repair.id,
        ...data,
      });
    },

    switchToStep(step) {
      this.active = step;
    },

    async downloadInspectionReport() {
      swal.fire({
        // title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "inspections/getReport",
          this.repair.inspection.id
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${this.repair.inspection.code}-report.pdf`
        );
        document.body.appendChild(link);
        link.click();
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
  },

  mounted() {},

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },

    // repair: {
    //   handler: function () {
    //     const repairData = cloneDeep(this.repair);
    //     delete repairData.items;

    //     this.$emit("onUpdateRepair", repairData);

    //     if (repairData.without_components != this.repairData.without_components) {
    //       this.startRepair(this.COMPONENTS_STEP);
    //     }
    //   },
    //   deep: true,
    // },

    repairData(value) {
      if (!isEqual(this.repair, value)) {
        this.repair = cloneDeep(value);
      }
    },

    loading(value) {
      this.isLoading = value;
      if (!value) {
        this.showRepairCancelModel = false;
        this.showStartRepairModal = false;
        this.showEndRepairModal = false;
        this.showStartApproveRepairModal = false;
        this.showEndApproveRepairModal = false;
      }
    },
    hourlyRate(value) {
      const items = cloneDeep(this.items);
      for (const itemKey in items) {
        if (!this.$idExist(items[itemKey]?.id)) {
          items.splice(itemKey, 1, {
            ...items[itemKey],
            work_amount: value,
          });
        }
      }
      this.items = items;
    },
  },
};
</script>

<style>
.blur-sm {
  filter: blur(2px);
}
.z-10 {
  z-index: 10;
}
.form-control.bg-transparent {
  background-color: transparent !important;
}
.w-90px {
  width: 90px;
}
</style>
